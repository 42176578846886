import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  faFileDownload,
  faFilePdf,
  faHeadset,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'

import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { MemberDataActions, MemberSelectors } from 'src/app/member'
import { Attachment } from 'src/app/member/models/member'
import { PagesModels } from 'src/app/pages/index'
import { LoggingService } from 'src/app/shared'

@Component({
  selector: 'psrs-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>()
  faFileDownload: IconDefinition = faFileDownload
  faHeadset: IconDefinition = faHeadset
  faFilePdf: IconDefinition = faFilePdf
  attachmentList: Attachment[] = []
  listLabel: string = '';
  rowId = ''
  page: PagesModels.PageDefinition

  isPdfModalOpen: boolean = false
  modalLabel: string
  selectedPdfUrl: string

  closePdfModal () {
    this.isPdfModalOpen = false
  }

  downloadPdf () {
    this.loggingService.logEvent('User Downloaded Pdf')
    window.open(this.selectedPdfUrl, '_blank')
  }

  constructor (
    private store: Store,
    private router: Router,
    // private signatoryService: SignatoryService,
    private loggingService: LoggingService
  ) {
    this.attachmentList =
      this.router.getCurrentNavigation().extras.state.attachmentList
    this.page = this.router.getCurrentNavigation().extras.state.page
    this.listLabel = this.router.getCurrentNavigation().extras.state.listLabel;
  }

  ngOnInit (): void {
  }

  ngOnDestroy (): void {
    this.unsubscribe$.next()
  }

  private prepareAttachment(attachment: Attachment) {
    this.store.dispatch(MemberDataActions.downloadAttachment({fileId: attachment.id}));
    return this.store.select(MemberSelectors.downloadedAttachment).pipe(
      first(res => !!res),
      map(blob => URL.createObjectURL(blob))
    );
  }

  viewAttachment(attachment: Attachment) {
    this.prepareAttachment(attachment).subscribe(uri => {
      this.router.navigate(['pages', this.page.id, 'document'], {
        state: {
          label: attachment.documentName,
          uri,
          override: {
            label: 'Attachment List',
            route: ['/pages', this.page.id, 'attachment-list'],
            state: {
              attachmentList: this.attachmentList,
              page: this.page,
              listLabel: this.listLabel
            }
          }
        }
      });
    });
  }

  downloadAttachment(attachment: Attachment) {
    this.prepareAttachment(attachment).subscribe(uri => {
      const a = document.createElement('a');
      a.href = uri;
      a.download = attachment.fileName;
      a.click();
    })
  }
}
